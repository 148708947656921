import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入插件Element Plus
import ElementPlus from 'element-plus'
// 引入Element Plus 默认样式文件
import 'element-plus/dist/index.css'
// 引入Element Plus icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// 引入Element Plus 中文
import locale from 'element-plus/lib/locale/lang/zh-cn'
// 引入语言包
import i18n from '@/i18n'

// 引入全局方法
import * as PcUtils from '@/utils/PcUtils.js'
import * as AppUtils from '@/utils/AppUtils.js'

// 调试版
import Vconsole from 'vconsole'
const disableDebugVersion = ['www.51vcard.com', '51vcard.com', 'localhost:8031']

// 注册组件
const app = createApp(App)
app.config.globalProperties.$pcUtils = PcUtils
app.config.globalProperties.$appUtils = AppUtils
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

if (disableDebugVersion.indexOf(window.location.host) === -1) {
  let vConsole = new Vconsole
  app.use(vConsole)
}

app.use(store).use(router).use(ElementPlus, { locale }).use(i18n).mount('#app')
