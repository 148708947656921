import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'

//得到默认使用的语言
const getDefaultLang = () => {
    if (localStorage.getItem('vcard_lang')) {
        return localStorage.getItem('vcard_lang')
    } else {
        // 根据游览器选择
        if (navigator.language === 'zh-CN') {
            return 'zh'
        } else if (navigator.language === 'en') {
            return 'en'
        } else {
            return 'zh'
        }
    }
}

let i18n = new createI18n({
    // legacy: false,  // 没有该参数可能会报错
    globalInjection: true, // 全局生效$t
    locale: getDefaultLang(),
    messages: {
        en, zh,
        'en-US': {
            ...Object.assign({}, en)
        },
        'zh-US': {
            ...Object.assign({}, zh)
        }
    }
})

export default i18n