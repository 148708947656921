import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'PcContentPage',
    component: () => import("../container/pc/ContentPage.vue"),
    children: [
      {
        // 首页
        path: "home",
        component: () => import("../container/pc/home/HomePage.vue"),
        name: "HomePage"
      }, {
        // 卡权益
        path: "cardEquity",
        component: () => import("../container/pc/cardEquity/CardEquityPage.vue"),
        name: "CardEquityPage"
      }, {
        // 帮助中心
        path: "helpCenter",
        component: () => import("../container/pc/helpCenter/HelpCenterPage.vue"),
        name: "HelpCenterPage"
      }, {
        // 操作指引
        path: "operationGuide",
        component: () => import("../container/pc/helpCenter/OperationGuidePage.vue"),
        name: "OperationGuidePage"
      }, {
        // 常见问题
        path: "problemDetail",
        component: () => import("../container/pc/helpCenter/ProblemDetailPage.vue"),
        name: "ProblemDetailPage"
      }, {
        // 谷歌验证器
        path: "googleValidator",
        component: () => import("../container/pc/googleValidator/GoogleValidatorPage.vue"),
        name: "GoogleValidatorPage"
      }, {
        // 关于我们
        path: "aboutUs",
        component: () => import("../container/pc/aboutUs/AboutUsPage.vue"),
        name: "AboutUsPage"
      }
    ],
    meta: {
      // auth: true
    }
  }, {
    path: '/app',
    name: 'AppContentPage',
    component: () => import("../container/app/ContentPage.vue"),
    children: [
      {
        path: "partner",
        component: () => import("../container/app/partner/PartnerPage.vue"),
        name: "PartnerPage",
        meta: { title: '升级' }
      }, {
        // 合伙人名单
        path: "partnerlist",
        component: () => import("../container/app/partner/PartnerListPage.vue"),
        name: "PartnerListPage",
        meta: { title: '合伙人名单' }
      }
    ]
  }, {
    // 合伙人
    // cookie条款
    path: "/cookieClause",
    component: () => import("../container/app/protocol/CookieClausePage.vue"),
    name: "CookieClausePage",
    meta: { title: 'cookie条款' }
  }, {
    // 用户协议
    path: "/userAgreement",
    component: () => import("../container/app/protocol/UserAgreementPage.vue"),
    name: "UserAgreementPage",
    meta: { title: 'VCard卡用户协议' }
  }, {
    // VCard AML
    path: "/vCardAML",
    component: () => import("../container/app/protocol/VCardAMLPage.vue"),
    name: "VCardAMLPage",
    meta: { title: 'VCard AML' }
  }, {
    // VCard隐私条款
    path: "/vCardPrivacyPolicy",
    component: () => import("../container/app/protocol/VCardPrivacyPolicyPage.vue"),
    name: "VCardPrivacyPolicyPage",
    meta: { title: 'VCard隐私条款' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log('router-before', to, from)
//   next()
// })

router.afterEach((to, from) => {
  // console.log('router-after', to, from)
  if (to.meta.title) {
    // 遍历meta改变title
    document.title = to.meta.title
  }
})

export default router
