export default {
  iszh: true,
  aboutUs: {
    headTitle: '关于我们',
    headTxt1: '我们悦商集团是一家致力于为不同行业提供全球多元化SaaS技术服务及数字化转型工具的企业，2022年7月在美国纳斯达克上市，股票代码WETG，接受美国会计事务所审计，同时持有美国MSB牌照，并接受FinCEN (金融犯罪执法局) 的合规监管，绝对保障您的资金安全。',
    headTxt2: '我们是基于悦商集团WTPay系统研发出来的一款一站式金融钱包，旨在可以帮助您轻松地进行全球支付和理财，让货币消费更简单。我们竭诚为您提供更便捷的方式来进行数字货币消费，让数字货币消费日常化、生活化，也可以让数字货币支付的好处让更多的消费者了解，推动全球数字经济发展。',
    topDescArr: [
      { name: '监管合规要求', desc: '建立国家级高标准合规团队，严格遵循国际反洗钱、反恐融资标准，严格遵循相关法律法规的要求，防范金融风险。' },
      { name: '隐私数据保护', desc: '信息和隐私受隐私政策保护，严格管控，最大限度降低滥用风险，任何数据传输均有技术加密，访问受限到严格物理控制。' },
      { name: '资金交易保障', desc: '所有操作均需客户的指令进行，未经授权和直接指令，任何第三方均不可对您的资金进行操作。' }
    ],
    infoArr: [
      { name: '我们的产品', btnName: '开始使用', desc: [
        { txt: 'VCard是一款BTC、ETH、USDT加密货币钱包，可以管理您的英镑、欧元和美元电子账户。' },
        { txt: '您可以使用VCard进行英镑、欧元、美元等法定货币与BTC、ETH、USDT的交易和转账，畅享无国界电子货币支付。' },
        { txt: 'VCard具有明确、合法的运营主体，满足金融监管规定的资本金要求、信息安全要求和KYC/AML标准。' }
      ] },
      { name: '合规资质', btnName: '开始使用', desc: [
        { txt: '美国是世界上对加密货币最友好的国家之一，并且通过Fincen(金融犯罪执法局)对所有在其境内注册的加密货币机构进行严格的监管。' },
        { txt: '而我们严格遵循Fincen(金融犯罪执法局)制定的规则，已经获得相关加密货币运营服务的许可，即 美国MSB牌照可实现Master发卡系统和数字货币兑换系统等相关服务。' },
        { txt: '我们始终以客户的最大利益为出发点，公平、诚实地行事。' }
      ] },
      { name: '场景应用', desc: [
        { txt: '可申请个人VCard卡，支持绑定国内外消费平台，自由充值畅享消费。' }
      ] }
    ]
  },
  cardEquity: {
    title: '卡权益',
    equityArr: [
      { tab: 'Lite卡', arr: [ '10 USDT', '2 USDT', '20,000 USD', '2.00%'] },
      { tab: '标准卡', arr: [ '0 USDT', '1 USDT', '20,000 USD', '1.35%' ] },
      { tab: '高级卡', arr: [ '10 USDT', '0.5 USDT', '50,000 USD', '1.20%' ] },
      { tab: '白金卡', arr: [ '50 USDT', '0 USDT', '200,000 USD', '1.00%' ] },
      { tab: '黑金卡', arr: [ '999 USDT\n或\n活动空投', '0 USDT', '无限额', '0.80%' ] }
    ],
    equityDesc: [ '开卡费用', '月费', '卡片额度/月', '卡片充值费用' ],
    equityBtn: '开始使用'
  },
  contentPage: {
    menuArr: [
      { name: '首页' },
      { name: '卡权益' },
      { name: '帮助中心' },
      { name: '谷歌身份验证器' },
      { name: '关于我们' },
      { name: '简体中文', 'subArr': [
        { name: '简体中文' },
        { name: 'ENGLISH' }
      ] },
      { name: '登录' },
      { name: '注册' }
    ],
    footLTitle: 'VCard，让货币消费更简单',
    footRaTitle: '加入官方Telegram群'
  },
  googleValidator: {
    title: '谷歌身份验证器下载'
  },
  home: {
    banner1: {
      title: 'VCard，让货币消费更简单',
      txt: '无论您身处世界哪个角落，无论您使用哪种货币，\n让消费从未如此简单。'
    },
    iconArr: [
      { name: '申请注册', desc: '通过web页面轻松在注册，只需要一个邮箱或者手机号即可' },
      { name: '完成KYC', desc: '下载APP之后，按照指示一步一步完成流程，只需2分钟系统自动完成，这再简单不过了' },
      { name: '放心使用', desc: '获取卡片后，充值，消费，APP可以详细查看每一笔充值、消费记录，让你随时掌握，管理您的财务状况' }
    ],
    infoArr: [
      { name: '轻而易举存储，\n管理您的资金', desc: '通过我们的应用程序管理您的资产，并随时使用您的卡片付款', btnName: '开始使用' },
      { name: 'VCard 卡', desc: [
        { txt: '使用VCard，支持全球网上支付，线下消费，绑定第三方支付' }
        // { txt: '使用VCard，您可以轻松将BTC，ETH等加密货币转换为法币' },
        // { txt: '使用VCard，您可以无缝切换加密世界和法币世界' }
      ], btnName: '开始使用' },
      { name: '轻松买卖加密货币', desc: 'APP内置兑换系统，让您轻松完成加密货币和法币的兑换，包括USD/EUR/GBP', btnName: '开始使用' },
      { name: '安全', desc: [
        { tit: '多重签名冷钱包', txt: '使用多重签名钱包系统，确保您的资产安全并在我们的离线系统中受到保护。' },
        { tit: '终端设备绑定', txt: '只有您授权的终端设备才能访问您的账户。所有的数据通信都采用了AES 256加密算法进行加密。' },
        { tit: '金融与安全监管', txt: '我们接受外部公司定期的财务审计和监督，以保证用户的资金账户被单独隔离' }
      ], btnName: '开始使用' }
    ],
    collapseTitle: '常见问题',
    collapseArr: [
      { title: '1、VCard 卡是信用卡还是借记卡？', desc: 'VCard 卡是一种预付卡。从广义上讲，预付卡与借记卡相同。不同之处在于借记卡与您的银行账户相关联，但预付卡需要充值。' },
      { title: '2、如何申请 VCard 卡？', desc: '首先使用手机号或者邮箱注册VCard，下载APP之后按照提示步骤完成KYC，一般系统会花费2分钟时间来检验，通过之后即可在APP首页上点击申请，您将会立刻收到一张VCard 卡，点击安全码后可以获得卡片详细信息。' }
      // { title: '3、如何为我的 VCard 卡充值？', desc: '您可以在VCard应用程序中使用加密货币兑换成USD，比如使用USDT，然后将兑换好的USD充值到VCard 卡中，即可开启全球消费。' }
    ],
    collapseBtnName: '了解更多'
  }
}