import { createStore } from 'vuex'

export default createStore({
  state: {
    isWeixin: () => {
      return window.navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    },
    isPC: () => {
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
      var flagPc = Agents.some(ele => {
        return navigator.userAgent.indexOf(ele) !== -1
      })
      return !flagPc
    },
    iosTestUrl: 'https://testflight.apple.com/join/dQSQ0Ppv', // ios内测跳转
    googleUrl: 'https://vcard-app.oss-cn-hongkong.aliyuncs.com/Vcard.apk',
    vcardEail: 'kf@51vcard.com', // e-mail邮箱地址
    officialGroupUrl: 'https://t.me/VCard_WTP',
    scrollView: '',
    pageScrollTop: 0  // 滚动值
  },
  getters: {
    getScrollView(state) {
      return state.scrollView
    },
    getPageScrollTop(state) {
      return state.pageScrollTop
    }
  },
  mutations: {
    addScrollView(state, info) {
      state.scrollView = info
    },
    addPageScrollTop(state, info) {
      state.pageScrollTop = info
    }
  },
  actions: {
  },
  modules: {
  }
})
