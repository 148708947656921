// import Connect from './Connect'
import { ElLoading, ElMessage } from 'element-plus'
import Clipboard from 'clipboard'
import store from '@/store'

// 给scrollbar加入动画滚动
export const addScrollAnimate = (scrollview) => {
  if (scrollview) {
    if (!scrollview.scrollTot) {
      scrollview.__proto__.scrollTot = ((top, time = 200) => {
        time && time < 10 ? time = 10 : time = Math.floor(time)
        let beginValue = store.state.pageScrollTop
        const ist = beginValue - top > 0 ? false : true
        const jl = Math.abs(beginValue - top)
        const bili = jl / time * 10
        let clock = setInterval(() => {
          time -= 10
          ist ? beginValue += bili : beginValue -= bili
          // console.log('addScrollAnimate', beginValue, time, bili)
          scrollview.scrollTop = beginValue
          if (time <= 0) {
            clearInterval(clock)
          }
        }, 10)
      })
    }
    if (!scrollview.setScrollTop) {
      scrollview.__proto__.setScrollTop = (top) => {
        scrollview.scrollTop = top
      }
    }
  }
}

// 设置loading
export const pageLoading = (options = {
  lock: true,
  text: 'Loading',
  spinner: 'el-icon-loading',  // 'el-loading-spinner' 'el-icon-loading'
  background: 'rgba(0, 0, 0, 0.7)'
}) => {
  let loadingInstance = ElLoading.service(options)
  return loadingInstance
}

// 复制方法
export const clipboardFn = (eleName = '.copyButton', text, func, isRemind = true) => {
  if (text) {
    let clip = new Clipboard(eleName, { text: () => text })
    clip.on('success', () => {
      isRemind && ElMessage({
        type: 'success',
        message: `复制成功`,
        duration: 1200
      })
      clip.destroy()
      func && func(true)
    })
    clip.on('error', () => {
      isRemind && ElMessage({
        type: 'error',
        message: `复制失败`,
        duration: 1200
      })
      clip.destroy()
      func && func(false)
    })
  } else {
    console.log('无数据')
  }
}

// 字符串转日期格式
export const strTransDate = (strDate) => {
  if (!strDate) { return }
  var t = Date.parse(strDate)
  if (typeof strDate === 'number') {
    return new Date(strDate)
  } else if (strDate.indexOf('T') !== -1) {
    // '2023-02-10T08:06:35.000+00:00' => 2023-02-10 08:06:35
    const jsonDate = new Date(strDate).toJSON()
    var tt = new Date(new Date(jsonDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    return new Date(tt)
  } else if (!isNaN(t)) {
    return new Date(Date.parse(strDate.replace(/-/g, "/")))
  } else {
    return new Date()
  }
}

// 格式化日期：yyyy-MM-dd HH:mm:ss
export const formatDate = (date, format = 'yyyy-MM-dd') => {
  date = typeof date === 'object' ? date : strTransDate(date)
  const myyear = date.getFullYear()
  let mymonth = date.getMonth() + 1
  let myweekday = date.getDate()
  let myhour = date.getHours()
  let myminute = date.getMinutes()
  let mysecond = date.getSeconds()
  if (mymonth < 10) {
    mymonth = `0${mymonth}`
  }
  if (myweekday < 10) {
    myweekday = `0${myweekday}`
  }
  if (myhour < 10) {
    myhour = `0${myhour}`
  }
  if (myminute < 10) {
    myminute = `0${myminute}`
  }
  if (mysecond < 10) {
    mysecond = `0${mysecond}`
  }
  format = format.replace('yyyy', myyear)
  format = format.replace('MM', mymonth)
  format = format.replace('dd', myweekday)
  format = format.replace('HH', myhour)
  format = format.replace('mm', myminute)
  format = format.replace('ss', mysecond)
  return format
}

// 倒计时函数
export const limitFormatMillSecondsTime = (millSeconds, isZero) => {
  if (millSeconds < 0) {
    millSeconds = 0
  }
  let d = Math.floor(((millSeconds / 1000) / 3600) / 24)
  let h = Math.floor(((millSeconds / 1000) / 3600) % 24)
  let m = Math.floor(((millSeconds / 1000) % 3600) / 60)
  let s = Math.floor(((millSeconds / 1000) % 3600) % 60)
  let ms = Math.floor(millSeconds / 100) % 100 % 60 % 10
  if (h < 10 && isZero) {
    h = `0${h}`
  }
  if (m < 10 && isZero) {
    m = `0${m}`
  }
  if (s < 10 && isZero) {
    s = `0${s}`
  }
  return {
    d,
    h, // 时
    m, // 分
    s, // 秒
    ms // 秒后面一位数
  }
}

//base64转blob
export const base64ToBlob = (code) => {
  let parts = code.split('base64,')
  let contentType = parts[0].split(':')[1]
  let raw = window.atob(parts[1])
  let rawLength = raw.length
  let uInt8Array = new Uint8Array(rawLength)
  for (let i in rawLength) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

// 导出文件
export const exportExcel = (data, name) => {
  let blob = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" })   // 设置文件类型excel
  let blName = `${formatDate(new Date(), 'yyyyMMddHHmmss')}.xlsx`
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.style.display = 'none'
  link.download = `${(name || '') + blName}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
