export default {
  iszh: false,
  aboutUs: {
    headTitle: 'About Us',
    headTxt1: 'We are a company dedicated to solving multi-scenario applications of cryptocurrencies for individuals, holds a US MSB license and is subject to Fincen (Financial Crimes Enforcement Bureau) compliance supervision.',
    headTxt2: 'We provide a one-stop financial wallet to solve their global payment and wealth management problems, making cryptocurrency consumption a daily routine. We hope that our efforts can promote the life of cryptocurrencies, help more consumers understand the benefits of cryptocurrency payments, and promote the development of the global digital economy.',
    topDescArr: [
      { name: 'Regulatory compliance requirements', desc: 'Establish a national-level high-standard compliance team, strictly follow international anti-money laundering and counter-terrorism financing standards, strictly comply with the requirements of relevant laws and regulations, and prevent financial risks.' },
      { name: 'Privacy Data Protection', desc: 'information and privacy are protected by privacy policies, strictly controlled to minimize the risk of abuse, any data transmission is technically encrypted, access is restricted to strict physical control.' },
      { name: 'Guaranteed transaction of funds', desc: 'All operations are subject to the instructions of the client, unauthorized and direct instructions, and no third party can operate on your funds.' }
    ],
    infoArr: [
      { name: 'our products', btnName: 'Start', desc: [
        { txt: 'VCard is a BTC, ETH, USDT cryptocurrency wallet that can manage your GBP, EUR and USD electronic accounts.' },
        { txt: 'You can use VCard to trade and transfer GBP, EUR, USD and other fiat currencies with BTC, ETH, USDT, and enjoy borderless electronic money payment.' },
        { txt: 'VCard has a clear and legal operating entity that meets the capital requirements, information security requirements and KYC/AML standards stipulated by financial regulations.' }
      ] },
      { name: 'compliance qualification', btnName: 'Start', desc: [
        { txt: 'The United States is one of the most crypto-friendly countries in the world, and through Fincen (Financial Crimes Enforcement Bureau), all cryptocurrency institutions registered in its territory are strictly regulated.' },
        { txt: 'and we strictly follow the rules set by Fincen (Financial Crimes Enforcement Bureau) and have obtained the license of relevant cryptocurrency operation services, namely the US MSB license which can realize MasterCard card issuing system and digital currency exchange system and other related services.' },
        { txt: 'We always act fairly and honestly in the best interests of our clients.' }
      ] },
      { name: 'Scenario application', desc: [
        { txt: 'You can apply for a personal Master card, support binding to domestic and foreign consumption platforms, and freely recharge and enjoy consumption.' }
      ] }
    ]
  },
  cardEquity: {
    title: 'Card Equity',
    equityArr: [
      { tab: 'Lite card', arr: [ '10 USDT', '2 USDT', '20,000 USD', '2.00%'] },
      { tab: 'Standard card', arr: [ '0 USDT', '1 USDT', '20,000 USD', '1.35%' ] },
      { tab: 'Advanced Card', arr: [ '10 USDT', '0.5 USDT', '50,000 USD', '1.20%' ] },
      { tab: 'Platinum card', arr: [ '50 USDT', '0 USDT', '200,000 USD', '1.00%' ] },
      { tab: 'Gold and black card', arr: [ 'Active airdrop', '0 USDT', 'No limit', '0.80%' ] }
    ],
    equityDesc: [ 'Card opening fee', 'Monthly fee', 'Card limit/month', 'Card recharge charge' ],
    equityBtn: 'Start to use'
  },
  contentPage: {
    menuArr: [
      { name: 'Home' },
      { name: 'Card Equity' },
      { name: 'Help Center' },
      { name: 'Google Authenticator' },
      { name: 'About Us' },
      { name: 'ENGLISH', 'subArr': [
          { name: '简体中文' },
          { name: 'ENGLISH' }
      ] },
      { name: 'Log in' },
      { name: 'Sign in' }
    ],
    footLTitle: 'VCard makes it easier to spend coins',
    footRaTitle: 'Join the official Telegram group'
  },
  googleValidator: {
    title: 'Google Authenticator download'
  },
  home: {
    banner1: {
      title: 'VCard, making it easier to spend coins \nVisa card, Master card',
      txt: 'No matter where you are in the world, no matter what fiat or \ncryptocurrency you hold, payment has never been easier.'
    },
    iconArr: [
      { name: 'Application for Registration', desc: 'It is easy to register through the web page, just need an email or mobile phone number' },
      { name: 'Complete KYC', desc: 'After downloading the APP, follow the instructions to complete the process step by step. It only takes 2 minutes for the system to complete automatically' },
      { name: 'Use with confidence', desc: 'After obtaining the card, recharge and consume, the APP can view each recharge and consumption record in detail, so that you can grasp and manage your financial situation at any time' }
    ],
    infoArr: [
      { name: 'Easy to store,\nManage your money and cryptocurrencies', desc: 'Manage your assets through our app and make payments with your card at any time Start to use', btnName: 'Start to use' },
      { name: 'VCard Master card', desc: [
        { txt: 'VCard is used to support global online payment, offline consumption and third-party payment' },
        { txt: 'With VCard, you can easily convert cryptocurrencies such as BTC and ETH into legal coins' },
        { txt: 'With VCard, you can seamlessly switch between the crypto world and the fiat world' }
      ], btnName: 'Start to use' },
      { name: 'Buy and sell cryptocurrencies easily', desc: 'The built-in exchange system allows you to easily exchange cryptocurrencies and fiat currencies, including USD/EUR/GBP', btnName: 'Start to use' },
      { name: 'Security', desc: [
        { tit: 'Multi-signature cold wallet', txt: 'Use a multi-signature cryptocurrency wallet system to ensure your assets are safe and protected in our offline system.' },
        { tit: 'Terminal Device Binding', txt: 'Only your authorized terminal device can access your account. All data communication is encrypted using AES 256 encryption algorithm.' },
        { tit: 'Financial and safety regulation', txt: `We are subject to regular financial audit and supervision by external companies to ensure that users' money accounts are segregated.` }
      ], btnName: 'Start to use' }
    ],
    collapseTitle: 'Q&A',
    collapseArr: [
      { title: '1. Is VCard Master a credit card or a debit card?', desc: 'VCard Master is a prepaid card. Prepaid cards are the same as debit cards. The difference is that debit cards are linked to your bank account, but prepaid cards need to be topped up. In our product, you can recharge with cryptocurrency.' },
      { title: '2. How to apply for a VCard Master card?', desc: 'First of all, you can register VCard with your mobile phone number or email address, download the APP and complete KYC according to the prompted steps. Generally, the system will take 2 minutes to check. After passing the application, you can click the application on the home page of the APP, and you will immediately receive a VCard Master card.' },
      { title: '3. How do I recharge my VCard Master card?', desc: 'You can use cryptocurrencies in the VCard app to convert to USD, such as USDT, and then recharge the converted USD into a VCard Master card to make global payment.' }
    ],
    collapseBtnName: 'Learn more'
  }
}