// import Connect from './Connect'
import { ElLoading, ElMessage } from 'element-plus'
import Clipboard from 'clipboard'
import store from '@/store'

export const isCssW = () => {
  let clientWidth = document.documentElement.clientWidth || document.body.clientWidth
  return (clientWidth >= 100 &&  clientWidth <= 640)
}

// 给scrollbar加入动画滚动
export const addScrollAnimate = (scrollview) => {
  if (scrollview) {
    if (!scrollview.scrollTot) {
      scrollview.__proto__.scrollTot = ((top, time = 200) => {
        time && time < 10 ? time = 10 : time = Math.floor(time)
        let beginValue = store.state.pageScrollTop
        const ist = beginValue - top > 0 ? false : true
        const jl = Math.abs(beginValue - top)
        const bili = jl / time * 10
        let clock = setInterval(() => {
          time -= 10
          ist ? beginValue += bili : beginValue -= bili
          // console.log('addScrollAnimate', beginValue, time, bili)
          scrollview.scrollTop = beginValue
          if (time <= 0) {
            clearInterval(clock)
          }
        }, 10)
      })
    }
    if (!scrollview.setScrollTop) {
      scrollview.__proto__.setScrollTop = (top) => {
        scrollview.scrollTop = top
      }
    }
  }
}

// 设置loading
export const pageLoading = (options = {
  lock: true,
  text: 'Loading',
  spinner: 'el-icon-loading',  // 'el-loading-spinner' 'el-icon-loading'
  background: 'rgba(0, 0, 0, 0.7)'
}) => {
  let loadingInstance = ElLoading.service(options)
  return loadingInstance
}

// 复制方法
export const clipboardFn = (eleName = '.copyButton', text, func, isRemind = true) => {
  if (text) {
    let clip = new Clipboard(eleName, { text: () => text })
    clip.on('success', () => {
      isRemind && ElMessage({
        type: 'success',
        message: `复制成功`,
        duration: 1200
      })
      clip.destroy()
      func && func(true)
    })
    clip.on('error', () => {
      isRemind && ElMessage({
        type: 'error',
        message: `复制失败`,
        duration: 1200
      })
      clip.destroy()
      func && func(false)
    })
  } else {
    console.log('无数据')
  }
}

// 格式化日期：yyyy-MM-dd HH:mm:ss
export const formatDate = (date, format = 'yyyy-MM-dd') => {
  date = date ? new Date(date) : new Date()
  const myyear = date.getFullYear()
  let mymonth = date.getMonth() + 1
  let myweekday = date.getDate()
  let myhour = date.getHours()
  let myminute = date.getMinutes()
  let mysecond = date.getSeconds()
  if (mymonth < 10) {
    mymonth = `0${mymonth}`
  }
  if (myweekday < 10) {
    myweekday = `0${myweekday}`
  }
  if (myhour < 10) {
    myhour = `0${myhour}`
  }
  if (myminute < 10) {
    myminute = `0${myminute}`
  }
  if (mysecond < 10) {
    mysecond = `0${mysecond}`
  }
  format = format.replace('yyyy', myyear)
  format = format.replace('MM', mymonth)
  format = format.replace('dd', myweekday)
  format = format.replace('HH', myhour)
  format = format.replace('mm', myminute)
  format = format.replace('ss', mysecond)
  return format
}

//base64转blob
export const base64ToBlob = (code) => {
  let parts = code.split('base64,')
  let contentType = parts[0].split(':')[1]
  let raw = window.atob(parts[1])
  let rawLength = raw.length
  let uInt8Array = new Uint8Array(rawLength)
  for (let i in rawLength) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

// 导出文件
export const exportExcel = (data, name) => {
  let blob = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" })   // 设置文件类型excel
  let blName = `${formatDate(false, 'yyyyMMddHHmmss')}.xlsx`
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.style.display = 'none'
  link.download = `${(name || '') + blName}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
